import React from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import MyShoppingToolsSupport from "./Pages/MyShoppingTools/MyShoppingToolsSupport";
import Layout from './Pages/Layout';
import MyShoppingToolsPrivacyPolicy from "./Pages/MyShoppingTools/MyShoppingToolsPrivacyPolicy";

function App() {
    return (
        <div className={"bg-light"}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route path="myshoppingtools/support" element={<MyShoppingToolsSupport/>}/>
                        <Route path="myshoppingtools/privacypolicy" element={<MyShoppingToolsPrivacyPolicy/>}/>
                        <Route
                            path=""
                            element={<Navigate to="/myshoppingtools/support" replace/>}
                        />
                        <Route
                            path="*"
                            element={<Navigate to="/myshoppingtools/support" replace/>}
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
