import React, {FC, useEffect} from 'react';

const AppName = "My Shopping Tools ";


const MyShoppingToolsPrivacyPolicy: FC = () => {

    useEffect(() => {
        document.title = "My Shopping Tools";
    })

    return (
        <div className={"d-flex align-items-center justify-content-center"} style={{minHeight: "100vh", paddingTop: "1rem", paddingBottom: "1rem"}}>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="card shadow-5-strong border" >
                        <div className="card-body">
                            <h5 className="card-title">Privacy Policy</h5>
                            <p className="card-text">{AppName} is committed to protecting your privacy and ensuring the security of your personal information. While using the App, we do not collect any personal information from you unless explicitly provided by you for specific purposes (e.g., creating an account or contacting support). If we collect any personal information, we will not willingly sell, share, or give it to anyone, except in the following circumstances:</p>
                            <ul>
                                <li>Legal Obligations: We may disclose your personal information if we are lawfully subpoenaed or required by law enforcement agencies or government authorities to produce such information. However, we will make reasonable efforts to notify you before disclosing unless prohibited by law.</li>
                            </ul>
                            <p><strong>Data Security:</strong> We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                            <p><strong>Changes to the Privacy Policy:</strong> We may update our privacy policy from time to time to reflect changes in our data practices or legal requirements. We will provide notice of any significant changes and seek your consent if required by applicable laws.</p>
                            <p><strong>Your Rights:</strong> You have the right to access, correct, update, or delete your personal information that we may have. You can exercise these rights by contacting us using the information provided below.</p>
                            <p><strong>Contact Us:</strong> If you have any questions, concerns, or requests regarding our privacy policy or the handling of your personal information, please contact us at <a href="mailto:MyHelperTools@gmail.com">MyHelperTools@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyShoppingToolsPrivacyPolicy;


