import React, {FC, PropsWithChildren} from 'react';
import {Outlet} from "react-router-dom";

const Layout: FC<PropsWithChildren> = ({children}) => {
    return (
        <div className={"container-lg"}>
            <Outlet/>
        </div>
    );
}

export default Layout;


