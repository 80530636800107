import React, {FC, useEffect} from 'react';

const MyShoppingToolsSupport: FC = () => {

    useEffect(() => {
        document.title = "My Shopping Tools";
    })

    return (
        <div className={"d-flex align-items-center justify-content-center"} style={{height: "100vh"}}>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="card shadow-5-strong border" style={{marginBottom: "20vh"}}>
                        <div className="card-body">
                            <h5 className="card-title">Support</h5>
                            <p className="card-text">Thank you for using My Shopping Tools!</p>
                            <p className="card-text">If you have any questions or need assistance please send an email
                                to <a href="mailto:MyHelperTools@gmail.com">MyHelperTools@gmail.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyShoppingToolsSupport;


